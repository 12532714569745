@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=PT+Serif:wght@400;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    font-family: 'Poppins', sans-serif !important;
}

.MuiTypography-root {
    font-family: 'Poppins', sans-serif !important;
}

// logo and humbergur alignment
.css-x5zaxz {
    align-items: center;
}

// loading spinner
.material-spinner {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    min-height: 50px;
    width: 50px;
    border-radius: 100px;
    border: 5px transparent solid;
    border-top: 5px #2196f3 solid;
    animation: material-spinner 4s infinite;
}

@keyframes material-spinner {
    0% {
        transform: rotate(0deg);
        border-top: 5px #2196f3 solid;
    }

    25% {
        transform: rotate(360deg);
        border-top: 5px #f44336 solid;
    }

    50% {
        transform: rotate(720deg);
        border-top: 5px #3f51b5 solid;
    }

    75% {
        transform: rotate(1080deg);
        border-top: 5px #4caf50 solid;
    }

    100% {
        transform: rotate(1440deg);
        border-top: 5px #ffc107 solid;
    }
}

// from button loading
.loading-form-button {
    position: relative;
    padding: 12px 20px;
    height: 44px;
    width: 92px;
    text-align: center;
    color: transparent;
    transition: all 0.4s ease-in-out;

    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -4px;
        margin-left: -4px;
        content: '';
        height: 10px;
        width: 10px;
        background: white;
        border-radius: 100%;
        transition: all 0.4s linear;
        animation: test 2s infinite;
        opacity: 1;
    }
}

@keyframes one-two {
    0% {
        left: 30%;
        top: 50%;
    }

    12.5% {
        top: 30%;
    }

    25% {
        top: 50%;
    }

    37.5% {
        top: 70%;
    }

    50% {
        left: 70%;
        top: 50%;
    }

    62.5% {
        top: 30%;
    }

    75% {
        top: 50%;
    }

    87.5% {
        top: 70%;
    }

    100% {
        left: 30%;
        top: 50%;
    }
}

@keyframes test {
    0% {
        left: 30%;
    }

    50% {
        left: 70%;
    }

    100% {
        left: 30%;
    }
}

// form label color
form label {
    color: #7c7a7a !important;
    font-weight: 500 !important;
}

label.MuiButton-containedPrimary {
    color: #ffffff !important;
}

// calendar grid customize
.attendances {
    background-color: #ccffce !important;
    opacity: 1 !important;
    color: #000000 !important;
    border: 0px !important;
    padding: 5px;
    text-align: left;
    text-overflow: ellipsis;
    // display: block;
}

.holidays {
    background-color: #c4f1ff !important;
    opacity: 1 !important;
    color: #000000 !important;
    border: 0px !important;
    padding: 5px !important;
    text-overflow: ellipsis;

    // display: block;
    .fc-event-title {
        color: #000000 !important;
        font-weight: bold;
        text-overflow: ellipsis;
        // display: block;
    }
}

.leaves {
    background-color: #f8977e !important;
    opacity: 1 !important;
    color: #000000 !important;
    border: 0px !important;
    padding: 5px !important;
    text-overflow: ellipsis;
    // display: block;
}

button.fc-today-button.fc-button.fc-button-primary {
    text-transform: capitalize;
}

.fc-event-time,
.fc-list-event-time,
.fc-daygrid-event-dot,
.fc-list-event-dot {
    display: none !important;
}

.fc-h-event .fc-event-main {
    color: #000000 !important;
}

.fc-button-group button {
    text-transform: capitalize !important;
    box-shadow: none !important;
}

.fc-button-group button:focus {
    box-shadow: none !important;
}

.fc-list-event-title:hover {
    background-color: 'black' !important;
}

// listing table body
.MuiTableContainer-root.css-rorn0c-MuiTableContainer-root {
    min-height: 320px;
}

// // Slider
// .slick-slider .slick-arrow:before {
//     color: #333;
//     font-size: 2rem;
// }

// .slick-slider .slick-dots button:before {
//     font-size: 1.5rem;
//     color: teal;
// }

// .slick-slider .slick-dots li.slick-active button::before {
//     color: teal;
// }

// .slick-slider .slick-dots {
//     bottom: -44px;
// }

// .slick-slider .slick-dots li {
//     margin: 0 0.5rem;
// }

input#date {
    height: 20px;
}
.image-container:hover > svg {
    visibility: visible !important;
}
.procard {
    // background-image: linear-gradient(to right top, #c64ff9, #4895ff, #00bfff, #00ddff, #1cf3f8);
}
.icon1 {
    font-size: 27px !important;
}

.custom-comment {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
